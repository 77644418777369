<template>
  <div>
    <div class="container-fluid no-gutters px-0">
      <div class="row">
        <div class="col-lg-12 mt-3xx mb-2">
          <search-bar :bread-crumb-only="true"></search-bar>

          <div class="card">
            <form @submit.prevent="onSubmitted">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-6">
                    <div
                      class="form-group row"
                      :class="{ 'has-error': model.first_name.$invalid }"
                    >
                      <label for="first_name" class="col-sm-4 col-form-label text-right">
                        Group Name
                      </label>
                      <div class="col-sm-8">
                        <input
                          class="form-control"
                          :class="{ 'form-control-danger': model.first_name.$invalid }"
                          type="text"
                          :disabled="isShow"
                          v-model="model.first_name.$model"
                        />

                        <error-msg :property="model.first_name" />
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div
                      class="form-group row"
                      :class="{ 'has-error': model.group_category_id.$invalid }"
                    >
                      <label
                        for="group_category_id"
                        class="col-sm-4 col-form-label text-right"
                      >
                        Group Category
                      </label>
                      <div class="col-sm-8">
                        <select
                          class="form-control"
                          :disabled="isShow"
                          v-model="model.group_category_id.$model"
                          @change="onChangeGroupCategory($event)"
                          v-if="groupcategories.data"
                        >
                          <option
                            v-for="(item, index) in groupcategories.data"
                            :key="index"
                            :value="item.group_category_id"
                          >
                            {{ item.group_category_name }}
                          </option>
                        </select>

                        <records-not-found
                          :message="'No <strong>group categories </strong> exist'"
                          v-else
                        />

                        <error-msg :property="model.group_category_id" />
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div
                      class="form-group row"
                      :class="{ 'has-error': model.group_sub_category_id.$invalid }"
                    >
                      <label
                        for="group_sub_category_id"
                        class="col-sm-4 col-form-label text-right"
                      >
                        Group Sub Category
                      </label>
                      <div class="col-sm-8">
                        <select
                          class="form-control"
                          :disabled="isShow"
                          v-model="model.group_sub_category_id.$model"
                          v-if="groupsubcategories.data"
                        >
                          <option
                            v-for="(item, index) in groupsubcategories.data"
                            :key="index"
                            :value="item.group_sub_category_id"
                          >
                            {{ item.group_sub_category_name }}
                          </option>
                        </select>

                        <records-not-found
                          :message="'No <strong>group sub categories </strong> exist'"
                          v-else
                        />

                        <error-msg :property="model.group_sub_category_id" />
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div
                      class="form-group row"
                      :class="{ 'has-error': model.branch_id.$invalid }"
                    >
                      <label for="branch_id" class="col-sm-4 col-form-label text-right">
                        Branch
                      </label>
                      <div class="col-sm-8">
                        <select
                          class="form-control"
                          :disabled="isShow"
                          v-model="model.branch_id.$model"
                          v-if="branches.data"
                        >
                          <option
                            v-for="(item, index) in branches.data"
                            :key="index"
                            :value="item.branch_id"
                          >
                            {{ item.branch_name }}
                          </option>
                        </select>

                        <error-msg :property="model.branch_id" />
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div
                      class="form-group row"
                      :class="{ 'has-error': model.phone.$invalid }"
                    >
                      <label for="phone" class="col-sm-4 col-form-label text-right">
                        Phone
                      </label>
                      <div class="col-sm-8">
                        <div class="row">
                          <span class="col-sm-5">
                            <select
                              class="form-control"
                              :disabled="isShow"
                              v-model="model.phone_country.$model"
                              v-if="countries.data"
                            >
                              <option
                                v-for="(item, index) in countries.data"
                                :key="index"
                                :value="item.iso2"
                              >
                                {{ item.iso2 }} ({{ item.phonecode }})
                                <!-- ({{ item.phonecode }}) - {{ item.name }} -->
                              </option>
                            </select>
                          </span>

                          <span class="col-sm-7">
                            <input
                              class="form-control"
                              :class="{ 'form-control-danger': model.phone.$invalid }"
                              type="text"
                              :disabled="isShow"
                              v-model="model.phone.$model"
                            />
                          </span>

                          <error-msg :property="model.phone" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div
                      class="form-group row"
                      :class="{ 'has-error': model.email.$invalid }"
                    >
                      <label for="email" class="col-sm-4 col-form-label text-right">
                        Contact Email
                      </label>
                      <div class="col-sm-8">
                        <input
                          class="form-control"
                          :class="{ 'form-control-danger': model.email.$invalid }"
                          type="text"
                          :disabled="isShow"
                          v-model="model.email.$model"
                        />

                        <error-msg :property="model.email" />
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div
                      class="form-group row"
                      :class="{ 'has-error': model.country_id.$invalid }"
                    >
                      <label for="country_id" class="col-sm-4 col-form-label text-right">
                        Country
                      </label>
                      <div class="col-sm-8">
                        <select
                          class="form-control"
                          :disabled="isShow"
                          v-model="model.country_id.$model"
                          v-if="countries.data"
                        >
                          <option value="" selected v-if="!isEdit">Select Country</option>
                          <option
                            v-for="(item, index) in countries.data"
                            :key="index"
                            :value="item.country_id"
                          >
                            {{ item.name }}
                          </option>
                        </select>

                        <error-msg :property="model.country_id" />
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div
                      class="form-group row"
                      :class="{ 'has-error': model.county_id.$invalid }"
                    >
                      <label for="county_id" class="col-sm-4 col-form-label text-right">
                        County
                      </label>
                      <div class="col-sm-8">
                        <select
                          class="form-control"
                          :disabled="isShow"
                          v-model="model.county_id.$model"
                          v-if="counties.data"
                          @change="onChangeCounty($event)"
                        >
                          <option
                            v-for="(item, index) in counties.data"
                            :key="index"
                            :value="item.county_id"
                          >
                            {{ item.name }}
                          </option>
                        </select>

                        <error-msg :property="model.county_id" />
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div
                      class="form-group row"
                      :class="{ 'has-error': model.constituency_id.$invalid }"
                    >
                      <label
                        for="constituency_id"
                        class="col-sm-4 col-form-label text-right"
                      >
                        Constituency
                      </label>
                      <div class="col-sm-8">
                        <select
                          class="form-control"
                          :disabled="isShow"
                          v-model="model.constituency_id.$model"
                          v-if="constituencies.data"
                          @change="onChangeConstituency($event)"
                        >
                          <option
                            v-for="(item, index) in constituencies.data"
                            :key="index"
                            :value="item.constituency_id"
                          >
                            {{ item.name }}
                          </option>
                        </select>

                        <records-not-found
                          :message="'Please select <strong>county</strong>'"
                          v-else
                        />

                        <error-msg :property="model.constituency_id" />
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div
                      class="form-group row"
                      :class="{ 'has-error': model.ward_id.$invalid }"
                    >
                      <label for="ward_id" class="col-sm-4 col-form-label text-right">
                        Ward
                      </label>
                      <div class="col-sm-8">
                        <select
                          class="form-control"
                          :disabled="isShow"
                          v-model="model.ward_id.$model"
                          v-if="wards.data"
                        >
                          <option
                            v-for="(item, index) in wards.data"
                            :key="index"
                            :value="item.ward_id"
                          >
                            {{ item.name }}
                          </option>
                        </select>

                        <records-not-found
                          :message="'Please select <strong>constituency</strong>'"
                          v-else
                        />

                        <error-msg :property="model.ward_id" />
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div
                      class="form-group row"
                      :class="{ 'has-error': model.street_address.$invalid }"
                    >
                      <label
                        for="street_address"
                        class="col-sm-4 col-form-label text-right"
                      >
                        Street Address
                      </label>
                      <div class="col-sm-8">
                        <input
                          class="form-control"
                          :class="{
                            'form-control-danger': model.street_address.$invalid,
                          }"
                          type="text"
                          :disabled="isShow"
                          v-model="model.street_address.$model"
                        />

                        <error-msg :property="model.street_address" />
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div
                      class="form-group row"
                      :class="{ 'has-error': model.type_of_business.$invalid }"
                    >
                      <label
                        for="type_of_business"
                        class="col-sm-4 col-form-label text-right"
                      >
                        Type of Business
                      </label>
                      <div class="col-sm-8">
                        <input
                          class="form-control"
                          :class="{
                            'form-control-danger': model.type_of_business.$invalid,
                          }"
                          type="text"
                          :disabled="isShow"
                          v-model="model.type_of_business.$model"
                        />

                        <error-msg :property="model.type_of_business" />
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div
                      class="form-group row"
                      :class="{ 'has-error': model.group_reg_no.$invalid }"
                    >
                      <label
                        for="group_reg_no"
                        class="col-sm-4 col-form-label text-right"
                      >
                        Group Reg No
                      </label>
                      <div class="col-sm-8">
                        <input
                          class="form-control"
                          :class="{ 'form-control-danger': model.group_reg_no.$invalid }"
                          type="text"
                          :disabled="isShow"
                          v-model="model.group_reg_no.$model"
                        />

                        <error-msg :property="model.group_reg_no" />
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6" v-if="isShow">
                    <div
                      class="form-group row"
                      :class="{ 'has-error': model.status_id.$invalid }"
                    >
                      <label for="status_id" class="col-sm-4 col-form-label text-right"
                        >Status</label
                      >
                      <div class="col-sm-8">
                        <div>
                          <div class="form-check-inline my-1">
                            <div class="custom-control custom-radio">
                              <input
                                type="radio"
                                id="status_active"
                                name="status_id"
                                v-model="model.status_id.$model"
                                value="1"
                                :disabled="isShow"
                                class="custom-control-input"
                              />
                              <label class="custom-control-label" for="status_active"
                                >Active</label
                              >
                            </div>
                          </div>

                          <div class="form-check-inline my-1">
                            <div class="custom-control custom-radio">
                              <input
                                type="radio"
                                id="status_inactive"
                                name="status_id"
                                v-model="model.status_id.$model"
                                value="99"
                                :disabled="isShow"
                                class="custom-control-input"
                              />
                              <label class="custom-control-label" for="status_inactive"
                                >Inactive</label
                              >
                            </div>
                          </div>
                        </div>

                        <error-msg :property="model.status_id" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr class="small" v-if="!isCustomer" />

              <div class="card card-clean" v-if="!isCustomer">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-7">
                      <div class="row title-height">
                        <div class="col-sm-12">
                          <h4 class="card-title">
                            Group Members ({{ getGroupMembersCount }})
                            <small class="top-color" v-if="!isShow">
                              - click member to remove</small
                            >
                          </h4>
                        </div>
                      </div>

                      <div class="group-height border-inner" data-simplebar>
                        <div class="activity">
                          <div class="table-responsive">
                            <transition-group
                              class="list-group list-group-flush border"
                              name="group-member-list"
                              tag="ul"
                              v-if="getGroupMembersCount > 0"
                            >
                              <li
                                class="list-group-item item-member group-member-list-item"
                                :id="item.customer_id"
                                v-for="(item, index) in group_members"
                                :key="index"
                                @mouseover="showGroupMembersByIndex = index"
                                @mouseout="showGroupMembersByIndex = null"
                              >
                                <div class="row">
                                  <div class="col-lg-4" @click="removeItem(item)">
                                    {{ item.full_name }}
                                  </div>
                                  <div class="col-lg-4" @click="removeItem(item)">
                                    {{ item.phone }}
                                  </div>
                                  <div class="col-lg-2">
                                    <input
                                      name="member_no"
                                      v-model="item.member_no"
                                      class="form-control-xs input-sm"
                                      :disabled="isShow"
                                    />
                                  </div>
                                  <div class="col-lg-2">
                                    <i
                                      class="fas fa-check icon-active-member"
                                      v-if="isShow"
                                    ></i>
                                    <i
                                      class="fas fa-check icon-active-member"
                                      v-show="showGroupMembersByIndex !== index"
                                      v-if="!isShow"
                                    ></i>
                                    <i
                                      class="fas fa-trash icon-delete-member"
                                      v-show="showGroupMembersByIndex === index"
                                      v-if="!isShow"
                                      @click="removeItem(item)"
                                    ></i>
                                  </div>
                                </div>
                              </li>
                            </transition-group>

                            <div
                              class="alert alert-danger border-0 text-center alert-clean"
                              role="alert"
                              v-else
                            >
                              <strong>No Group Members Selected</strong>
                            </div>
                          </div>
                        </div>
                      </div>

                      <input
                        class="form-control"
                        type="hidden"
                        v-model="model.group_member_ids.$model"
                      />
                    </div>

                    <div class="col-lg-5" v-if="!isShow">
                      <div class="row title-height">
                        <div class="col-sm-8">
                          <h4 class="card-title">
                            Available Members ({{ customers_data.pagination?.total }}) -
                            <small class="top-color">click to add</small>
                          </h4>
                        </div>
                        <div class="col-sm-4">
                          <input
                            class="form-control-sm form-control"
                            type="text"
                            :disabled="isShow"
                            placeholder="Search"
                            v-model="searchText"
                            @input="filterMembers"
                          />
                        </div>
                      </div>
                      <div class="group-height border-inner" data-simplebar>
                        <div class="activity">
                          <div class="table-responsive">
                            <transition-group
                              class="list-group list-group-flush border"
                              name="group-member-list"
                              tag="ul"
                              v-if="customers_data.data.length > 0"
                            >
                              <li
                                class="list-group-item item-all-members group-member-list-item"
                                :id="item.customer_id"
                                @click="addItem(item)"
                                v-for="(item, index) in customers_data.data"
                                :key="index"
                                @mouseover="showAllGroupMembersByIndex = index"
                                @mouseout="showAllGroupMembersByIndex = null"
                              >
                                <div class="row">
                                  <div class="col-lg-5">{{ item.full_name }}</div>
                                  <div class="col-lg-5">{{ item.phone }}</div>
                                  <div class="col-lg-2">
                                    <i
                                      class="fas fa-plus text-light-grey"
                                      v-show="showAllGroupMembersByIndex !== index"
                                    ></i>
                                    <i
                                      class="fas fa-plus text-white"
                                      v-show="showAllGroupMembersByIndex === index"
                                    ></i>
                                  </div>
                                </div>
                              </li>
                            </transition-group>

                            <div
                              class="alert alert-danger border-0 text-center alert-clean"
                              role="alert"
                              v-else
                            >
                              <strong>No Available Members</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr class="small" />

              <div class="card card-clean">
                <div class="card-body">
                  <div class="row">
                    <!-- {{ customers_pagination.total }} <br>
                                    {{ customers_data }} -->

                    <!-- kkkk -->
                    <div class="col-lg-12">
                      <!-- {{ model.latitude.$model }}  ----
                                        {{ model.longitude.$model }} -->

                      <div class="form-group">
                        <label
                          for="street_address"
                          class="col-sm-12 col-form-label no-gutters px-0 text-left"
                          >Group Location
                        </label>

                        <div class="col-sm-12 no-gutters px-0">
                          <map-leaflet
                            :latlng="{
                              lat: model.latitude.$model || 0,
                              lng: model.longitude.$model || 0,
                            }"
                            :streetAddress="model.street_address.$model"
                            @changedLocation="changedLocation"
                            @update:latlng="updateLatLng"
                            :isEdit="isEdit"
                            :isShow="isShow"
                            :isNew="isNew"
                            v-if="model.latitude.$model && model.longitude.$model"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <!-- show maker checker form buttons/ status -->
              <maker-checker-button-component
                :makerChecker="makerChecker"
                v-if="isMakerChecker"
              />

              <div class="col-lg-12" v-else>
                <div class="form-group">
                  <button
                    class="btn btn-primary mr-3"
                    type="submit"
                    :disabled="model.$invalid"
                    :class="{ 'btn-secondary': model.$invalid }"
                    v-if="!isShow"
                  >
                    <slot name="submitButton"></slot>
                  </button>

                  <button
                    class="btn btn-primary mr-3"
                    type="button"
                    @click="onEdit"
                    v-if="
                      (isShow && !isCustomer) ||
                      (isShow && isCustomer && isCustomerAdmin(group?.group_admins))
                    "
                  >
                    Edit
                  </button>

                  <router-link
                    class="btn btn-success mr-5"
                    :to="{
                      name: 'clientCustomerInviteGroupMembers',
                      params: { id: group.customer_id },
                    }"
                    v-if="isShow && isCustomer && isCustomerAdmin(group?.group_admins)"
                  >
                    Invite Group Members
                  </router-link>

                  <button class="btn btn-danger" type="button" @click="onCancelled">
                    Cancel
                  </button>
                </div>
              </div>
            </form>

            <div class="col-lg-12" v-if="group.status_id != '1' && !isCustomer">
              <hr class="row no-top-margin" />

              <div class="form-group">
                <a
                  class="btn btn-secondary btn-block"
                  @click.prevent="toggleActivateMemberPopup(group)"
                >
                  <i class="las la-toggle-on font-18"></i> Activate Group
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <activate-member
      :user-data="user_data"
      :user-type="'GROUP'"
      :toggle-activate-member-popup="toggleActivateMemberPopup"
      @toggleActivateMemberPopup="toggleActivateMemberPopup"
      v-if="show_activate_user_modal"
    >
    </activate-member>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch, toRef, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { useStore } from "vuex";

import _ from "lodash";

const groupForm_with_data = {
  first_name: "Great Men",
  email: "great@example.com",
  phone: "254723111444",
  phone_country: "KE",
  country_id: "113",
  county_id: undefined,
  constituency_id: undefined,
  ward_id: undefined,
  type_of_business: "2 Star Hotel",
  group_reg_no: "",
  branch_id: 2,
  group_member_ids: [],
  group_members: [],
  status_id: 1,
  street_address: "H. Selassie Ave.",
  longitude: "",
  latitude: "",
  group_category_id: undefined,
  group_sub_category_id: undefined,
  customer_id: undefined,
};

const groupForm = {
  first_name: "",
  email: "",
  phone: "",
  phone_country: "KE",
  country_id: "113",
  county_id: undefined,
  constituency_id: undefined,
  ward_id: undefined,
  type_of_business: "",
  group_reg_no: "",
  branch_id: undefined,
  group_member_ids: [],
  group_members: [],
  status_id: 1,
  street_address: "",
  longitude: "",
  latitude: "",
  group_category_id: undefined,
  group_sub_category_id: undefined,
  customer_id: undefined,
};

const module = "groups";
const group_data_module = "groupsdata";
const customer_module = "customers";
const branch_module = "branches";
const group_category_module = "groupcategories";
const group_sub_category_module = "groupsubcategories";
const country_module = "countries";
const county_module = "counties";
const constituency_module = "constituencies";
const ward_module = "wards";

export default defineComponent({
  name: "GroupDetail",

  components: {},

  props: {
    isEdit: {
      type: Boolean,
      required: true,
      default: false,
    },
    isShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCustomer: {
      type: Boolean,
      required: false,
      default: false,
    },
    authUser: {
      required: false,
    },
    isMakerChecker: {
      type: Boolean,
      required: false,
      default: false,
    },
    makerChecker: {
      type: Object,
      required: false,
    },
  },

  emits: ["submitForm", "cancelForm", "editForm"],

  setup(props, { emit }) {

    // avoid destructuring props directly in the root scope of setup
    const groupProp = toRefs(props);

    const store = useStore();

    let group = ref(groupForm);
    let group_members = ref([]);
    let group_member_ids = ref([]);
    let groupcategories = ref([]);
    let groupsubcategories = ref([]);
    let customers = ref([]);
    let customers_data = ref([]);
    let customers_new_data = ref([]);
    let customers_pagination = ref([]);
    let group_member_ids_data = ref([]);
    let branches = ref([]);
    let showGroupMembersByIndex = ref(null);
    let showAllGroupMembersByIndex = ref(null);
    let searchText = ref("");
    const show_activate_user_modal = ref(false);
    const user_data = ref(null);
    const isCustomer = ref(props.isCustomer);

    // load countries
    let country_payload = { report: "1" };
    store.dispatch(`${country_module}/fetchCountries`, country_payload);

    // load counties
    let county_payload = { country_id: "113", report: "1" };
    store.dispatch(`${county_module}/fetchCounties`, county_payload);

    

    // load branches
    let branch_payload = { report: "1" };
    store.dispatch(`${branch_module}/fetchBranches`, branch_payload);

    // load group categories
    let group_category_payload = { report: "1" };
    store.dispatch(
      `${group_category_module}/fetchGroupCategories`,
      group_category_payload
    );

    // if we are in edit mode
    if (props.isEdit || props.isShow) {
      if (isCustomer.value) {
        group = computed(() => store.getters[`${group_data_module}/group`]);
      } else {
        group = computed(() => store.getters[`${module}/group`]);
      }
    }

    
    // if we are in maker checker, use maker checker data
    if (groupProp.isMakerChecker.value) {
      group = groupProp.makerChecker.value?.data;
    }

    const rules = {
      first_name: { required },
      email: { email },
      phone: {},
      phone_country: {},
      country_id: {},
      county_id: {},
      constituency_id: {},
      ward_id: {},
      type_of_business: {},
      group_reg_no: {},
      branch_id: { required },
      group_category_id: { required },
      group_sub_category_id: { required },
      group_member_ids: {},
      group_members: {},
      status_id: {},
      street_address: {},
      longitude: {},
      latitude: {},
    };

    // validate form
    const model = useVuelidate(rules, group);

    function onSubmitted() {
      emit("submitForm", group);
    }

    function onCancelled() {
      emit("cancelForm", group);
    }

    function onEdit() {
      emit("editForm", group);
    }

    function isCustomerAdmin(group_admins) {
      if (group_admins != undefined) {
        return group_admins.find(function (item) {
          return item?.customer_id == props.authUser?.customer?.customer_id;
        });
      }
    }

    function onChangeGroupCategory(event) {
      // console.log("onChangeCountry event", event.target.value);
      let group_sub_category_payload = {
        group_category_id: event.target.value,
        report: "1",
      };
      store.dispatch(
        `${group_sub_category_module}/fetchGroupSubCategories`,
        group_sub_category_payload
      );
    }

    function changedLocation(location) {
      // console.log("changedLocation ", location);
      group.value.latitude = location.lat;
      group.value.longitude = location.lng;
      // console.log("group after changed ", group.value);
    }

    function addItem(item) {
      if (!props.isShow && !isCustomer.value) {
        // check if item already exists
        const itemexists = group.value.group_member_ids.some(
          (rec) => rec === item.customer_id
        );

        // console.log("itemexists", itemexists, item, group.value.group_member_ids);
        // add item if it doesn't exist
        if (!itemexists) {
          var index = customers.value.indexOf(item);
          if (index > -1) {
            customers.value.splice(index, 1);
          }

          group.value.group_member_ids.push(item.customer_id);
          group.value.group_members.push({
            customer_id: item.customer_id,
            full_name: item.full_name,
            phone: item.phone,
            member_no: "",
          });
          showAllGroupMembersByIndex.value = null;
          showGroupMembersByIndex.value = null;
        }
      }
    }

    function removeItem(item) {
      if (!props.isShow) {
        customers.value.push(item);
        group.value.group_member_ids.splice(
          group?.value.group_member_ids.indexOf(item.customer_id),
          1
        );
        group.value.group_members.splice(group?.value.group_members.indexOf(item), 1);
        showAllGroupMembersByIndex.value = null;
        showGroupMembersByIndex.value = null;
      }
    }

    /* if (!isCustomer.value) {
      group_members = computed(() => group?.value.group_members);
      group_member_ids = computed(() =>
        group?.value.group_members.map((a) => a.customer_id)
      );

      customers_data = computed(() => store.getters[`${customer_module}/customers`]);
    } */

    ////////////////////////////////////////////
    if (!isCustomer.value) {
      group_members = computed(() => group?.value?.group_members || []);
      group_member_ids = computed(
        () => group?.value?.group_members?.map((a) => a.customer_id) || []
      );

      customers_data = computed(() => store.getters[`${customer_module}/customers`]);
    }
    ////////////////////////////////////////////

    // if we are in edit mode
    if (props.isEdit) {
      if (!props.isCustomer) {
        customers.value = customers_data?.value.data;
      }

      // filter customers array
      /* if (group_members != undefined) {
                    customers.value = customers_data.value.data.filter( function(item){
                            return group.value.group_members.find(function(item2){
                                return item.customer_id == item2.customer_id;
                            }) == undefined;
                        }
                    );
                } */

      // START ON PAGE REFRESH
      // check if we should fetch counties
      if (group.value.country_id) {
        let country_payload = { country_id: group.value.country_id, report: "1" };
        store.dispatch(`${county_module}/fetchCounties`, country_payload);
      }

      // check if we should fetch constituencies
      if (group.value.county_id) {
        let county_payload = { county_id: group.value.county_id, report: "1" };
        store.dispatch(`${constituency_module}/fetchConstituencies`, county_payload);
      }

      // check if we should fetch wards
      if (group.value.constituency_id) {
        let constituency_payload = {
          constituency_id: group.value.constituency_id,
          report: "1",
        };
        store.dispatch(`${ward_module}/fetchWards`, constituency_payload);
      }

      // check if we should fetch group sub categories
      if (group.value.group_category_id) {
        let group_sub_category_payload = {
          group_category_id: group.value.group_category_id,
          report: "1",
        };
        store.dispatch(
          `${group_sub_category_module}/fetchGroupSubCategories`,
          group_sub_category_payload
        );
      }
      // END ON PAGE REFRESH
    } else {
      customers.value = customers_data.value.data;
    }

    // START ON ROUTE CHANGES
    // watch for country changes
    watch(
      () => group.value.country_id,
      (newVal, oldVal) => {
        // fetch counties
        let country_payload = { country_id: newVal, report: "1" };
        store.dispatch(`${county_module}/fetchCounties`, country_payload);
      }
    );

    // watch for county changes
    watch(
      () => group.value.county_id,
      (newVal, oldVal) => {
        // fetch constituencies
        let county_payload = { county_id: newVal, report: "1" };
        store.dispatch(`${constituency_module}/fetchConstituencies`, county_payload);
      }
    );

    // watch for constituency changes
    watch(
      () => group.value.constituency_id,
      (newVal, oldVal) => {
        // fetch wards
        let constituency_payload = { constituency_id: newVal, report: "1" };
        store.dispatch(`${ward_module}/fetchWards`, constituency_payload);
      }
    );

    // watch for group category changes
    watch(
      () => group.value.group_category_id,
      (newVal, oldVal) => {
        // fetch group sub categories
        let group_sub_category_payload = { group_category_id: newVal, report: "1" };
        store.dispatch(
          `${group_sub_category_module}/fetchGroupSubCategories`,
          group_sub_category_payload
        );
      }
    );

    // watch for group_member_ids changes
    watch(
      () => group_member_ids.value,
      (newVal, oldVal) => {
        // fetch fresh data
        filterData(newVal);
      }
    );
    // END ON ROUTE CHANGES

    // load all customers
    // let customer_payload = { 'report': '1', 'type': 'customer' };
    /* let customer_payload = { 'type': 'customer' };
            store.dispatch(`${customer_module}/fetchCustomersLess`, customer_payload); */
    filterData();

    // toggleActivateMemberPopup
    function toggleActivateMemberPopup(group) {
      // console.log("group", group);
      show_activate_user_modal.value = !show_activate_user_modal.value;
      user_data.value = group;
    }

    // call filterMembers after 500ms wait / debounce
    const filterMembersGd = _.debounce(() => {
      filterData();
    }, 500);

    const filterMembers = _.debounce(() => {
      console.log("filterMembers called. group_member_ids:", group_member_ids.value);

      // Ensure group_member_ids is defined and not null
      if (!group_member_ids || !group_member_ids.value) {
        return;
      }

      filterData(group_member_ids.value);
    }, 500);

    function filterData(group_member_ids) {
      if (!group_member_ids) {
        return;
      }
      // get data from backend
      let group_member_ids_data =
        group_member_ids && group_member_ids.length > 0 ? group_member_ids.join() : "";

      /* let group_member_ids_data =
        group_member_ids.length > 0 ? group_member_ids.join() : ""; */

      let searchStr = searchText.value.toString().toLowerCase();
      let customer_filter_payload = {
        type: "customer",
        is_group: "0",
        filter_ids: group_member_ids_data,
        search_terms: searchStr,
      };
      store
        .dispatch(`${customer_module}/fetchCustomersLess`, customer_filter_payload)
        .then(() => {
          customers_new_data = computed(
            () => store.getters[`${customer_module}/customers`]
          );
          customers_data = computed(() => customers_new_data.value.data);
          customers_pagination = computed(() => customers_new_data.value.pagination);
        });
    }

    function filterDataGd() {
      // get data from backend
      group_member_ids_data =
        group_member_ids.value.length > 0 ? group_member_ids.value.join() : "";
      // console.log("group_member_ids_data", group_member_ids_data);
      let searchStr = searchText.value.toString().toLowerCase();
      let customer_filter_payload = {
        type: "customer",
        is_group: "0",
        filter_ids: group_member_ids_data,
        search_terms: searchStr,
      };
      store
        .dispatch(`${customer_module}/fetchCustomersLess`, customer_filter_payload)
        .then(() => {
          customers_new_data = computed(
            () => store.getters[`${customer_module}/customers`]
          );
          customers_data = computed(() => customers_new_data.value.data);
          customers_pagination = computed(() => customers_new_data.value.pagination);
          // console.log("customers_data back", customers_data.value);
          // console.log("customers_pagination back", customers_pagination.value);
        });

      /* if (group_members != undefined) {
                    customers.value = customers_data.value.data
                        // filter out any entries already assigned to group
                        .filter( function(item){
                            return group.value.group_members.find(function(item2){
                                return item.customer_id == item2.customer_id;
                            }) == undefined;
                        })
                        // filter to match entered search string
                        .filter(function(item2) {
                            let searchStr = searchText.value.toString().toLowerCase();
                            let itemToCheck = item2.full_name.toString().toLowerCase();
                            let matches = itemToCheck.includes(searchStr);
                            return matches;
                        }
                    );
                } */
    }

    function onChangeCountry(event) {
      let country_payload = { country_id: event.target.value, report: "1" };
      store.dispatch(`${county_module}/fetchCounties`, country_payload);
    }

    function onChangeCounty(event) {
      let county_payload = { county_id: event.target.value, report: "1" };
      store.dispatch(`${constituency_module}/fetchConstituencies`, county_payload);
    }

    function onChangeConstituency(event) {
      let constituency_payload = { constituency_id: event.target.value, report: "1" };
      store.dispatch(`${ward_module}/fetchWards`, constituency_payload);
    }

    const getGroupMembersCount = computed(() => {
      return group_members.value ? group_members.value.length : 0;
    });

    // branches
    branches = computed(() => store.getters[`${branch_module}/branches`]);

    // groupcategories
    groupcategories = computed(
      () => store.getters[`${group_category_module}/groupcategories`]
    );

    // groupsubcategories
    groupsubcategories = computed(
      () => store.getters[`${group_sub_category_module}/groupsubcategories`]
    );

    // update location
    const updateLatLng = ({ lat, lng }) => {
      customer.value.company_latitude = lat;
      customer.value.company_longitude = lng;
    };

    return {
      submitForm: props.submitForm,
      // isCustomer,
      countries: computed(() => store.getters[`${country_module}/countries`]),
      counties: computed(() => store.getters[`${county_module}/counties`]),
      constituencies: computed(
        () => store.getters[`${constituency_module}/constituencies`]
      ),
      wards: computed(() => store.getters[`${ward_module}/wards`]),
      model,
      // isEdit: props.isEdit,
      // isShow: props.isShow,
      group,
      // customers: computed(() => customers_data.value ),
      // customers_pagination: computed(() => customers_pagination.value ),
      customers_data,
      branches,
      groupcategories,
      groupsubcategories,
      onSubmitted,
      onCancelled,
      onEdit,
      group_members,
      addItem,
      removeItem,
      group_member_ids,
      showGroupMembersByIndex,
      showAllGroupMembersByIndex,
      getGroupMembersCount,
      searchText,
      filterMembers,
      changedLocation,
      onChangeCountry,
      onChangeCounty,
      onChangeConstituency,
      onChangeGroupCategory,
      toggleActivateMemberPopup,
      show_activate_user_modal,
      user_data,
      isCustomerAdmin,
      updateLatLng,
    };
  },
});
</script>

<style scoped>
.group-member-list-item {
  transition: all 1s;
}
.group-member-list-enter,
.group-member-list-leave-to {
  max-height: 0px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  overflow: hidden;
}
.group-member-list-enter-to,
.group-member-list-leave {
  max-height: 80px;
}
</style>
